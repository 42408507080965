import { Box } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import withContext from "@/context";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Main from "@/pages/Main";
import DNA from "@/pages/DNA";
import TaskIntroduction from "@/pages/TaskIntroduction";
import Tasks from "@/pages/Tasks";
import VendorVerifyPage from "./pages/VendorVerifyPage";
import GenesisNFTPage from "./pages/GenesisNFTPage";

const App = () => (
  <BrowserRouter>
    <Navbar />
    <Box marginTop="94px">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/dna/:id" element={<TaskIntroduction />} />
        <Route path="/tasks/:id" element={<Tasks />} />
        <Route path="/dna" element={<DNA />} />
        <Route path="/vendorVerify" element={<VendorVerifyPage />} />
        <Route path="/genesisNFT" element={<GenesisNFTPage />} />
      </Routes>
    </Box>
    <Footer />
  </BrowserRouter>
);

export default withContext(App);

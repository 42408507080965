import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useState } from 'react';
import { Link as RouterLink } from "react-router-dom";

const NewNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full fixed top-0 left-0 z-50 backdrop-blur-sm bg-transparent">
      <nav className="flex items-center justify-between px-4 py-4 md:px-8 lg:px-16">
        {/* Logo */}
        <RouterLink to="/">
          <div className="flex items-center" >
            <img src="/assets/img/logo.svg" alt="Logo" className="w-12 h-12 md:w-16 md:h-16" />
          </div>
        </RouterLink>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 7.5h16.5M3.75 12h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center">
          <RouterLink to="/tasks/1" className="block px-4 py-2 hover:bg-white hover:text-black">
            TASKS
          </RouterLink>
          <a
            href="https://whitepaper.twin3.ai/"
            target="_blank"
            rel="noreferrer noopener"
            className="block px-4 py-2 hover:bg-white hover:text-black">
            WHITEPAPER
          </a>
          <RouterLink to="/dna" className="block px-4 py-2 hover:bg-white hover:text-black">
            MY TWIN
          </RouterLink>
          <ConnectButton />
        </div>
      </nav>

      {/* Mobile Dropdown */}
      <div
        className={`${isMenuOpen ? 'block' : 'hidden'
          } px-4 py-4 bg-gradient-to-r from-black to-transparent backdrop-blur-sm`}
      >
        <RouterLink to="/tasks/1" className="block px-4 py-2 text-white">
          TASKS
        </RouterLink>
        <RouterLink to="/whitepaper" className="block px-4 py-2 text-white">
          WHITEPAPER
        </RouterLink>
        <RouterLink to="/dna" className="block px-4 py-2 text-white">
          MY TWIN
        </RouterLink>
        <RouterLink to="/genesisNFT" className="block px-4 py-2 text-white">
          GENESIS NFT
        </RouterLink>
        <ConnectButton />
      </div>
    </header>
  );
};

export default NewNavbar;
import { useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import AccountContext from "@/context/account";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import Fire_png from "@/assets/images/Fire.png";
import Rectangle_png from "@/assets/images/Rectangle.png";

interface TaskNavigatorProps {
  leftPram?: string | number;
  topParam?: string | number;
}

const TaskNavigator = ({ leftPram, topParam }: TaskNavigatorProps) => {
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToGenesisNFTPage = () => {
    navigate("/genesisNFT");
  };

  const navigateToTestPage = () => {
    navigate("/dna");
  };

  return (
    <Box
      position="sticky"
      left={leftPram}
      top={topParam}
      height="100%"
      p={4}
      display={{ base: 'none', md: 'block' }}
      className={"rounded-lg border border-[#27272A] shadow-sm p-2"}
    >
      <Flex align="center">
        <Image src={Rectangle_png} width={8} height={8} />
        <Text fontWeight={300} m={2} pr={2}>
          {account?.toString().slice(0, 6)}...
          {account?.toString().slice(-4)}
        </Text>
        <Link
          href={`https://sepolia.arbiscan.io/address/${account}`}
          target="_target"
        >
          <ExternalLinkIcon />
        </Link>
      </Flex>
      <Flex direction={"column"} gap={1} fontSize="lg" lineHeight={2}>
        <Flex
          className={location.pathname === "/dna" ? "rounded-lg border border-[#27272A]" : undefined}
          p={1}
          align="center"
          cursor={"pointer"}
          onClick={navigateToTestPage}
        >
          <Text as="p" pl={1} mr={3} >
            ME
          </Text>
          {location.pathname === "/dna" && (
            <Image src='/assets/img/logo.svg' width={6} height={6} />
          )}
        </Flex>
        <Flex
          className={location.pathname === "/genesisNFT" ? "rounded-lg border border-[#27272A]" : undefined}
          align="center"
          cursor={"pointer"}
          padding={2}
          onClick={navigateToGenesisNFTPage}
        >
          <Text as="p" mr={3} >
            Genesis NFT
          </Text>
          {location.pathname === "/genesisNFT" && (
            <Image src='/assets/img/logo.svg' width={6} height={6} />
          )}
        </Flex>
        <Accordion allowToggle width={"100%"}>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton padding={2} justifyContent="space-between">
                  <Flex align="center" cursor={"pointer"}>
                    <Text fontSize={18} mr={3}>
                      Tasks
                    </Text>
                    {isExpanded && (
                      <Image src='/assets/img/logo.svg' width={6} height={6} />
                    )}
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <RouterLink to="/tasks/1">
                    <Text as="p" ml={4} cursor="pointer">
                      ↪Tasks 1
                    </Text>
                  </RouterLink>
                  <RouterLink to="/tasks/2">
                    <Text as="p" ml={4} cursor="pointer">
                      ↪Tasks 2
                    </Text>
                  </RouterLink>
                  <RouterLink to="/tasks/3">
                    <Text as="p" ml={4} cursor="pointer">
                      ↪Tasks 3
                    </Text>
                  </RouterLink>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
    </Box>
  );
};

export default TaskNavigator;

import { useReadContract } from 'wagmi';
import ABI from '@/abi/0xer_abi.json';
import { Twin3_CONTRACT_ADDRESS } from '../utils/constants';

const useTokenId = (account: string) => {
  const { data, error, isLoading } = useReadContract({
    address: Twin3_CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'tokenIdOf',
    args: [account],
  });

  const tokenId = data ? parseInt(data as string) : undefined;

  return { tokenId, error, isLoading };
};

export default useTokenId;

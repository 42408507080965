import { useReadContract } from 'wagmi';
import ABI from '@/abi/0xer_abi.json';
import { Twin3_CONTRACT_ADDRESS, RPC } from '../utils/constants';

const useLastSyncTime = (account: string) => {
  const {
    data: blockNumber,
    isError,
    isLoading,
  } = useReadContract({
    address: Twin3_CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'getLastSyncTime',
    args: [account],
  });

  const lastSyncTime = async () => {
    if (!blockNumber) return undefined;

    const blockInfo = await fetch(RPC || '', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: 'eth_getBlockByNumber',
        params: [`0x${Number(blockNumber).toString(16)}`, false],
        jsonrpc: '2.0',
        id: 1,
      }),
    }).then((response) => response.json());

    return blockInfo?.result?.timestamp
      ? parseInt(blockInfo.result.timestamp, 16) * 1000
      : undefined;
  };

  return {
    lastSyncTime,
    isError,
    isLoading,
  };
};

export default useLastSyncTime;

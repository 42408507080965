import React, { useState } from 'react';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>();

  const faqData = [
    {
      title: "Ch1 Soul Injection Protocol",
      content: "Activating the agentic human potential\" Twin3 launches with the revolutionary Soul Injection protocol, transforming human traits into intelligence assets through soulbound technology. Users create their Twin Matrix SBT, injecting selected traits to form personal agents. This marks the birth of authentic agentic humans in the digital realm."
    },
    {
      title: "Ch2 Third Party Integration",
      content: "Personal agents gain compatibility with major workflow platforms (Zapier, Make, n8n). This integration enables agents to participate in diverse AI workflows, demonstrating the value of human-in-the-loop collaboration across existing ecosystems."
    },
    {
      title: "Ch3 Intelligence Assets Framework",
      content: "\"Standardizing human intelligence assets\" Twin3 establishes the comprehensive framework for intelligence assets. The 256-dimension Twin Matrix evolves into a standardized protocol for encoding and trading human traits, setting the foundation for the agentic human economy."
    },
    {
      title: "Ch4 Agentic human protocol",
      content: "\"Building the talent network infrastructure\" Twin3 launches its native marketplace as the Agentic Human Protocol, connecting verified human agents with AI systems. This specialized network ensures authentic human traits are properly valued and efficiently deployed in AI collaboration."
    },
    {
      title: "Ch5 Intelligence to earn economy",
      content: "\"Monetizing human intelligence\" The Twin3 ecosystem enables direct value creation from intelligence assets. Personal agents generate sustainable income through task completion, while the Data DAO governance ensures fair value distribution and market integrity."
    },
    {
      title: "Ch6 Cross chain intelligence network",
      content: "\"Creating the unified talent network\" Twin3 expands across multiple blockchains, forming an interconnected intelligence asset network. The Agentic Human Protocol becomes the standard interface for human-AI collaboration across all digital platforms."
    },
    {
      title: "Ch7 Universal human protocol",
      content: "\"Establishing the human-AI collaboration standard\" Twin3 achieves recognition as the universal protocol for agentic humans. The ecosystem matures into a comprehensive talent network, enabling seamless collaboration between human intelligence assets and AI systems while preserving human agency."
    }
  ];

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="w-full bg-black md:px-12 px-5 lg:py-20 text-white">
      <h1 className="md:text-[56px] text-4xl tregular mb-8 text-start">
        <span className="text-[#525252]">STRATEGIC</span> PLAN
      </h1>

      <div>
        {faqData.map((item, index) => (
          <div
            key={index}
            className="flex lg:flex-row flex-col items-start lg:items-center border-b border-gray-700 py-8"
          >
            <button
              className="tregular text-[#525252] text-start text-2xl hover:text-white transition flex-shrink-0 mr-6"
              onClick={() => handleClick(index)}
            >
              {item.title}
            </button>
            <div className={`${activeIndex === index ? '' : 'hidden'}`}>
              <p className="2xl:px-44 xl:px-20 lg:text-start text-left leading-8 text-[#525252] pt-4 lg:pt-0">
                {item.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;

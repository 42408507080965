import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from '@chakra-ui/react';
import FAQSection from '@/components/FAQSection';
import MatrixAnimation from '@/components/MatrixAnimation';
import useTotalSupply from "@/libs/useTotalSupply";


const Main = () => {
  const { totalSupply: totalMint, isError } = useTotalSupply();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      console.error("Failed to fetch total supply");
    }
  }, [isError]);

  return (
    <>
      <MatrixAnimation />
      <Box marginTop="-94px">

          {/* <!-- Hero Section Start --> */}
          <section className="relative w-full min-h-screen flex items-center justify-start">
            {/* Background Video */}
            <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted playsInline>
              <source src="/assets/img/v2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute sm:bottom-0 md:bottom-0 lg:bottom-20 flex flex-wrap w-full z-10 lg:p-5 md:p-0 sm:p-0">
              {/* First Div */}
              <div className="w-full lg:w-3/5 bg-transparent h-auto flex flex-col md:items-center lg:items-start justify-between p-5 lg:p-10 relative overflow-hidden rounded-lg backdrop-blur-sm hover:backdrop-blur-sm text-center lg:text-left">
                <p className="text-[#525252] text-lg md:text-2xl uppercase">Preserve and empowering</p>
                <h1 className="tbold text-white text-4xl md:text-6xl lg:text-7xl lg:font-bold 2xl:text-[116px] leading-snug uppercase">
                  Human experience<br />
                  <span className="tlight text-[#525252] lg:font-light">in the AI era</span>
                </h1>
              </div>
              {/* Second Div */}
              <div className="w-full lg:w-1/5 bg-transparent h-auto flex flex-col md:items-center lg:items-start justify-between p-5 lg:p-10 relative overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 text-center lg:text-left">
                <p className="text-[#525252] text-base md:text-lg">Free gas to mint your Twin NFT for AI agents.</p>
                <h1 className="tsemibold text-white text-4xl md:text-6xl lg:text-8xl leading-snug uppercase">           {totalMint ?? 0}</h1>
                <button className="px-4 py-2 mt-2 border lg:w-full md:w-auto sm:w-auto border-[#373737] text-black bg-white rounded-lg hover:bg-transparent hover:text-white hover:border-white transition duration-300"
                  onClick={() => navigate("/tasks/1")}
                >
                  Free Mint
                </button>
              </div>
              {/* Third Div */}
              <div className="w-full lg:w-1/5 bg-transparent h-auto flex flex-col md:items-center lg:items-start justify-between p-2 md:p-4 relative overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 text-center lg:text-left">
                {/* Row for Images */}
                <div className="flex justify-center lg:justify-end space-x-2 md:space-x-4 w-full">
                  <a
                    href="https://x.com/twin3_ai"
                    target="_blank"
                    rel="noreferrer noopener">
                    <img src="/assets/img/twitter.png" alt="Icon 1" className="w-auto h-auto" />
                  </a>
                  <a
                    href="https://opensea.io/collection/twin3"
                    target="_blank"
                    rel="noreferrer noopener">
                    <img src="/assets/img/open sea.png" alt="Icon 2" className="w-auto h-auto" />
                  </a>
                  <a
                    href="https://warpcast.com/twin3.eth"
                    target="_blank"
                    rel="noreferrer noopener">
                    <img src="/assets/img/wrapcast.png" alt="Icon 3" className="w-auto h-auto" />
                  </a>
                </div>
                {/* Bottom Content */}
                <div className="flex flex-col items-center md:items-center lg:items-start">
                  <h1 className="text-white text-sm md:text-lg tsemibold">Track on us</h1>
                  <p className="text-[#525252] text-sm md:text-lg">Twitter, OpenSea, and WrapCast for the latest updates!</p>
                </div>
              </div>
            </div>

            {/* Scroll Indicator */}
            <div className="hidden lg:flex absolute bottom-5 w-full items-center">
              {/* Div 1 */}
              <div className="flex-grow h-px border-t border-[#525252]"></div>
              {/* Div 2 */}
              <div className="p-4">
                <p className="text-[#525252]">Scroll to explore</p>
              </div>
            </div>
          </section>
          {/* <!-- Hero Section End --> */}
          <section className="w-full border-b border-[#525252] px-4 md:px-8 lg:px-5 pb-8 xl:mt-20 lg:py-20">
            {/* Full-width Heading */}
            <h1 className="tregular md:text-start text-center lg:text-[74px] lg:leading-[92px] text-4xl leading-[60px] text-white mb-8 uppercase">
              Seal and Encrypt <span className="tlight text-[#525252]">
                Your Unique Traits into a Twin </span>Soulbound Token for Secure Digital Identity
            </h1>

            {/* Parent Div */}
            <div className="w-full flex flex-col md:flex-row gap-6">
              {/* Child Div 1 */}
              <div className="w-full md:w-1/2 flex flex-col gap-6">
                {/* Row 1 */}
                <div className="flex flex-col gap-6">
                  <div className="bg-transparent p-6 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300">
                    {/* Row for Images */}
                    <div className="flex justify-center lg:justify-end space-x-2 md:space-x-4 w-full">
                      <img src="/assets/img/1.png" alt="Icon 1" className="w-auto h-auto" />
                    </div>
                    <h2 className="tregular text-2xl text-white font-semibold mb-2">Soulbound Twin</h2>
                    <p className="text-gray-600">
                      Convert your traits across physical, digital, social and spiritual
                      realms into the Twin Matrix — a 256-dimensional soulbound representation of your authentic
                      self.
                    </p>
                  </div>
                  <div className="bg-transparent p-6 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300">
                    <div className="flex justify-center lg:justify-end space-x-2 md:space-x-4 w-full">
                      <img src="/assets/img/2.png" alt="Icon 1" className="w-auto h-auto" />
                    </div>
                    <h2 className="tregular text-2xl text-white font-semibold mb-2">Soul Injection</h2>
                    <p className="text-gray-600">
                      Inject selected traits into your personal agents. Each agent inherits
                      specific abilities, becoming your digital representatives in the AI economy.
                    </p>
                  </div>
                </div>
              </div>

              {/* Child Div 2 */}
              <div className="w-full md:w-1/2 bg-transparent p-6 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 flex flex-col justify-end relative">
                {/* Background Video */}
                <video
                  className="absolute top-0 left-0 w-full h-full -z-10 object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="/assets/img/v2.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className="flex justify-center lg:justify-end space-x-2 md:space-x-4 w-full">
                  <img src="/assets/img/3.png" alt="Icon 1" className="w-auto h-auto" />
                </div>
                <h2 className="text-2xl text-white tregular mb-2">Agentic Human</h2>
                <p className="text-gray-600">
                  Your agents work as human-in-the-loop partners in AI systems, automatically
                  finding and completing tasks in agent marketplaces to earn continuous value.
                </p>
              </div>
            </div>
          </section>
          {/* Service section start */}
          <section className="w-full border-b border-[#525252] md:px-12 px-5 lg:py-20">
            {/* Full-width Heading */}
            <h1 className="tregular text-start md:text-[74px] md:leading-[92px] text-6xl leading-[70px] text-white mb-8">
              Begin with Tasks <span className="tlight text-[#525252]">to Build Your </span>Twin Matrix
            </h1>

            {/* Parent Div */}
            <div className="w-full flex flex-col lg:flex-row gap-6 mb-4">
              <div className="w-full lg:h-auto md:h-[400px] h-[200px] lg:w-1/2 bg-transparent p-6 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 flex flex-col justify-end">
                {/* Background Video */}
                <video className="absolute top-0 left-0 w-full h-full -z-10 object-cover" autoPlay loop muted playsInline>
                  <source src="/assets/img/v4.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="w-full lg:w-1/2 bg-transparent p-2 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 flex flex-col justify-between">
                <div className="flex justify-center lg:justify-center w-full">
                  <img src="/assets/img/s1.png" alt="Icon 1" className="" />
                </div>
                <h2 className="tregular text-2xl text-white font-semibold p-2">Proof of Humanity</h2>
                <p className="text-gray-600 p-2">
                  Convert your traits across physical, digital, social and spiritual realms into the Twin Matrix — a 256-dimensional soulbound representation of your authentic self.
                </p>
                <button className="tsemibold px-4 py-2 border m-4 border-[#373737] text-white bg-transparent rounded-lg hover:bg-white hover:text-black hover:border-black transition duration-300"
                  onClick={() => navigate("/tasks/1")}
                >
                  Start now
                </button>
              </div>

              <div className="w-full lg:w-1/2 bg-transparent p-2 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 flex flex-col justify-between">
                <div className="flex justify-center lg:justify-center w-full">
                  <img src="/assets/img/s2.png" alt="Icon 1" className="" />
                </div>
                <h2 className="tregular text-2xl text-white font-semibold p-2">Human Trust Network</h2>
                <p className="text-gray-600 p-2">
                  Join a verified network of authentic humans on blockchain. Each person you verify strengthens the network's trust layer, building a reliable foundation for human-AI collaboration.
                </p>
                <button className="tsemibold px-4 py-2 border m-4 border-[#373737] text-white bg-transparent rounded-lg hover:bg-white hover:text-black hover:border-black transition duration-300">
                  Coming soon
                </button>
              </div>

              <div className="w-full lg:w-1/2 bg-transparent p-2 overflow-hidden rounded-lg backdrop-blur-sm border border-white border-opacity-20 hover:backdrop-blur-sm hover:border-opacity-100 hover:shadow-lg hover:shadow-white transition-all duration-300 flex flex-col justify-between" >
                <div className="flex justify-center lg:justify-center w-full">
                  <img src="/assets/img/s3.png" alt="Icon 1" className="" />
                </div>
                <h2 className="tregular text-2xl text-white font-semibold p-2">Web3 Expertise</h2>
                <p className="text-gray-600 p-2">
                  Validate your Web3 experience through 100 challenges. From Bitcoin to DeFi, prove your journey in the decentralized revolution to enhance your Twin Matrix capabilities.
                </p>
                <button className="tsemibold px-4 py-2 border border-[#373737] text-white bg-transparent rounded-lg hover:bg-white hover:text-black hover:border-black transition duration-300 m-4">
                  Coming soon
                </button>
              </div>
            </div>
          </section>
          {/* Service section end */}
          <FAQSection />
      </Box>

    </>
  )
}

export default Main;
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Center,
  ListItem,
  Text,
  UnorderedList,
  Tooltip,
  VStack,
  Image
} from "@chakra-ui/react";
import lock from "@/assets/images/lock.png";
import useNFTData from "@/hooks/useNFTData";
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setDisplayValues } from '@/state/nftRenderer/nftRendererSlice';
interface NFTRendererProps {
  values?: string;
  // @todo: better typings
  size?: any;
}

const VALUE_START_X = 65;
const VALUE_START_Y = 30;
const GAP_X = 27;
const GAP_Y = 30;
const OFFSET_START_X = 10;
const OFFSET_START_Y = 30;

// @todo: add other

const IndexInformation = ({
  title,
  lines,
}: {
  title: string;
  lines?: string[];
}) => (
  <Box maxW="60%">
    <Text fontSize="3xl" mb={5} fontWeight="bold">
      {title}
    </Text>
    <UnorderedList>
      {lines?.map((line: string, index) => (
        <ListItem key={index}>{line}</ListItem>
      ))}
    </UnorderedList>
  </Box>
);


const NFTRenderer = ({ values, size = 750 }: NFTRendererProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>();
  const { displayValues: displayValuesFromHooks } = useNFTData();
  const dispatch = useAppDispatch();
  const displayValuesFromStore = useAppSelector((state) => state.nftRenderer);

  useEffect(() => {
    if (displayValuesFromHooks.length === 0) {
      return;
    }
    dispatch(setDisplayValues(displayValuesFromHooks));
  }, [displayValuesFromHooks, dispatch]);

  const initialized = !!values;

  const selectCurrentIndex = useCallback(
    (index: number) => () => setCurrentIndex(index),
    []
  );

  const resetCurrentIndex = useCallback(() => setCurrentIndex(undefined), []);
  const indexInformation = useMemo(() => {
    if (currentIndex === 0) {
      // humanity index
      return (
        <IndexInformation
          title="#001 Humanity Index"
          lines={[
            "Address: 0x(0000)",
            "Goal: Calculate your likelihood of being human by passing various levels of human verification tests.",
          ]}
        />
      );
    } else if (currentIndex === 80) {
      // community builder index
      return (
        <IndexInformation
          title="#002 Community Builder Index"
          lines={["Address: 0x(0050)"]}
        />
      );
    } else if (currentIndex === 192) {
      // web3 knowledge Index
      return (
        <IndexInformation
          title="#003 Web3 Knowledge Level Index"
          lines={["Address: 0x(00C0)"]}
        />
      );
    }
    return <IndexInformation title="Coming Soon" />;
  }, [currentIndex]);

  const hasMeaningfulIndex = currentIndex != null && indexInformation;

  return (
    <Box width={size} height={size} position="relative" marginTop={4} className={"rounded-lg border border-[#27272A] shadow-sm p-2"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 500 500"
      >
        <style>{`text{fill:white;font-family:courier;font-size:16px;`}</style>
        <rect width="100%" height="100%" fill="black" />
        {Array.from({ length: 16 }).map((_, index: number) => (
          <text
            key={index}
            x={OFFSET_START_X}
            y={OFFSET_START_Y + GAP_Y * index}
            style={{
              fontFamily: "unbounded",
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '130%',
              fill: "#3E3E3E",
            }}
          >
            {(index * 16).toString(16).toUpperCase().padStart(4, "0")}
          </text>
        ))}
        {displayValuesFromStore.map((value, index: number) => {
          let fillColor;
          switch (value.status) {
            case 'not_on_chain':
              fillColor = "red";
              break;
            case 'not_on_chain_and_has_new_task':
              fillColor = "red";
              break;
            case 'on_chain':
              fillColor = "white";
              break;
            case 'empty_task':
              fillColor = "gray";
              break;
            case 'new_task':
              fillColor = "#34C759";
              break;
          }
          return value.status !== 'empty_task' ? (
            <Tooltip
              key={value.key}
              label={
                <Box
                  bg='orange'
                  borderRadius='10px'
                  pb={'3px'}
                  position="relative"
                >
                  <Box
                    bg="black"
                    color="white"
                    p={4}
                    borderRadius="20px"
                    position="relative"
                  >
                    <VStack align="start" spacing={2}>
                      {/* Title */}
                      <Text fontSize="lg" fontWeight="bold" color="orange.400">
                        {value.taskName}
                      </Text>
                      {/* Content */}
                      <Text fontSize="md" color="white">
                        • <strong>index</strong> : <span style={{ color: "orange" }}>{index}</span> / 255
                      </Text>
                    </VStack>
                  </Box>
                  <Box
                    position="absolute"
                    bottom="-15px"
                    left="50%"
                    transform="translateX(-50%)"
                    zIndex={-1}
                  >
                    <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.9625 53.8224C22.6493 55.4551 24.9628 55.4551 25.6496 53.8224L46.9951 3.07729C47.5496 1.75911 46.5816 0.301826 45.1515 0.301826H2.46061C1.03055 0.301826 0.062593 1.75911 0.617076 3.0773L21.9625 53.8224Z" fill="#FF9500" />
                    </svg>
                  </Box>
                </Box>
              }
              bg="transparent"  // 移除預設背景
              boxShadow="none"  // 移除預設陰影
              placement="top"
            >
              <text
                key={index}
                x={VALUE_START_X + GAP_X * (index % 16) - (value.status === 'new_task' && value.data === '00' ? 6 : -2)}
                y={VALUE_START_Y + Math.floor(index / 16) * GAP_Y - 1}
                style={{
                  cursor: "pointer",
                  fill: fillColor,
                  fontFamily: "unbounded",
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '130%',
                }}
                onClick={selectCurrentIndex(index)}
              >
                {value.status === 'new_task' && value.data === '00' ? 'new' : value.data}
              </text>
            </Tooltip>
          ) : (
            <text
              key={index}
              x={VALUE_START_X + GAP_X * (index % 16)}
              y={VALUE_START_Y + Math.floor(index / 16) * GAP_Y - 1}
              style={{
                fill: fillColor,
                fontFamily: "unbounded",
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '130%',
              }}
            >
              {value.data}
            </text>
          );
        })}
      </svg>
      <Center
        pointerEvents={hasMeaningfulIndex ? "auto" : "none"}
        position="absolute"
        width="100%"
        height="100%"
        top={0}
        left={0}
        bg="blackAlpha.600"
        backdropFilter={hasMeaningfulIndex ? "blur(2px)" : "none"}
        onClick={resetCurrentIndex}
        opacity={hasMeaningfulIndex ? 1 : 0}
        transition="opacity .2s, backdrop-filter .5s"
        m="auto"
      >
        {indexInformation}
      </Center>
      {!initialized && (
        <Center position="absolute" width="100%" height="100%" top={0} left={0}>
          <Center w="20%" h="20%" bg="whiteAlpha.600" m="auto">
            <Image src={lock} />
          </Center>
        </Center>
      )}
    </Box>
  );
};

export default NFTRenderer;

import { useContext, useEffect, useState } from "react";
import { getUser, getTasks } from "@/api";
import AccountContext from '@/context/account';
import { data_from_GET_me, display_type } from '@/type'

const compareData = (contractData: string, backendData: string) => {
  if (contractData.length !== backendData.length) {
    throw new Error('Data length mismatch');
  }

  let result: display_type[] = [];

  for (let i = 0; i < contractData.length; i += 2) {
    const contractSegment = contractData.slice(i, i + 2);
    const backendSegment = backendData.slice(i, i + 2);

    if (backendSegment === '00' && contractSegment === '00') {
      result.push({
        status: 'empty_task',
        data: backendSegment,
        key:
          `${Date.now()}-${Math.random().toString(36).slice(2)}`
      });
    } else if (backendSegment === contractSegment) {
      result.push({
        status: 'on_chain',
        data: backendSegment,
        key:
          `${Date.now()}-${Math.random().toString(36).slice(2)}`
      });
    } else if (backendSegment !== contractSegment) {
      result.push({
        status: 'not_on_chain',
        data: backendSegment,
        key:
          `${Date.now()}-${Math.random().toString(36).slice(2)}`
      });
    }
  }

  return result;
};

const useNFTData = () => {
  const { accountInfo } = useContext(AccountContext);
  const [userData, setUserData] = useState<data_from_GET_me>();
  const defaultMartixValue: display_type[] = Array.from({ length: 256 }).map(() => ({
    status: 'empty_task',
    data: '00',
    key: `${Date.now()}-${Math.random().toString(36).slice(2)}`
  }));
  const [displayValues, setDisplayValues] = useState<display_type[]>(defaultMartixValue);
  const [taskData, setTaskData] = useState<any[]>([]);

  useEffect(() => {
    if (!!accountInfo === false) {
      setDisplayValues(defaultMartixValue);
      return;
    }
    getUser().then((data) => {
      setUserData(data);
    }).catch((error) => {
      console.error('Error fetching user data:', error);
    });

    getTasks().then((data) => {
      setTaskData(data);
    }).catch((error) => {
      console.error('Error fetching tasks:', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  useEffect(() => {
    if (!userData || userData.on_chain_data === null || userData.data === null) {
      return;
    }
    const data_form_compareData = compareData(userData.on_chain_data, userData.data);

    taskData.forEach((task) => {
      const index = parseInt(task.index, 16);
      if (data_form_compareData[index]) {
        if (data_form_compareData[index].status === 'not_on_chain') {
          data_form_compareData[index].status = 'not_on_chain_and_has_new_task';
        } else if (data_form_compareData[index].status === 'on_chain' && data_form_compareData[index].data !== '00') {
          data_form_compareData[index].status = 'on_chain';
        }
        else {
          data_form_compareData[index].status = 'new_task';
        }
        data_form_compareData[index].taskName = task.name; // 將 task.name 加入到對應項目中
      } else {
        console.warn(`Index ${index} is out of bounds for data_form_compareData`);
      }
    });

    setDisplayValues(data_form_compareData); // 更新 displayValues 狀態
  }, [userData, taskData]);

  return { displayValues, userData, taskData };
};

export default useNFTData;
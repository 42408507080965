import { useCallback, useEffect } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import SCORE_ABI from '@/abi/Twin3ScoreExchange_abi.json';
import { Twin3ScoreExchange_CONTRACT_ADDRESS } from '@/utils/constants';
import { useToast } from '@chakra-ui/react';

const useExchangeGenesis = (amount: number | 1, id: number | 0) => {
  const { data: hash, writeContract } = useWriteContract();
  const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const toast = useToast();

  const submit = useCallback(
    async (signature: `0x${string}`) => {
      try {
        await writeContract({
          abi: SCORE_ABI,
          address: Twin3ScoreExchange_CONTRACT_ADDRESS,
          functionName: 'exchangeGenesis',
          args: [amount, id - 1],
        });
        console.log('exchangeGenesis', hash, isConfirmed);
        if (!hash && !isConfirmed)
          toast({
            title: 'Waiting for Exchange Score CONTRACT confirmation...',
            duration: 5000,
            status: 'success',
          });
      } catch (error) {
        console.error('Error executing transactions:', error);
        toast({
          title: 'Contract submission failed!',
          status: 'error',
        });
      }
    },
    [writeContract, amount, id, hash, isConfirmed, toast]
  );

  useEffect(() => {
    if (hash && isConfirmed) {
      toast({
        title: 'Exchange Score CONTRACT Success',
        status: 'success',
      });
    }
  }, [hash, toast, isConfirmed]);

  return { submit, isConfirmed };
};

export default useExchangeGenesis;

import { useState, useCallback, useEffect, useContext } from "react";
import { Text, Button, Image } from "@chakra-ui/react";
import { useAppSelector } from '../state/hooks';
import AccountContext from "@/context/account";

import Image_1 from "@/assets/images/gif/1.gif";
import Image_2 from "@/assets/images/gif/2.gif";
import Image_3 from "@/assets/images/gif/3.gif";
import Image_4 from "@/assets/images/gif/4.gif";
import Image_5 from "@/assets/images/gif/5.gif";
import Image_6 from "@/assets/images/gif/6.gif";

import { getSignature } from "@/api";
import useExchangeGenesis from "@/libs/useExchangeGenesis";
import useReadScore from '@/libs/useReadScore';
import { useToast } from "@chakra-ui/react";

interface ticketProps {
  id: number;
  name: string;
  src: string;
  points: number;
  refetchTotalSupply: () => void;
}

const GenesisTicket = ({ id, name, src, points, refetchTotalSupply }: ticketProps) => {
  const genesisNFTData = useAppSelector((state) => state.genesisNFT);
  const rankingData = useAppSelector((state) => state.rankingPanel);
  const { account } = useContext(AccountContext);
  const { refetch } = useReadScore(account || "");
  const toast = useToast();

  const imageMap = {
    "1.gif": Image_1,
    "2.gif": Image_2,
    "3.gif": Image_3,
    "4.gif": Image_4,
    "5.gif": Image_5,
    "6.gif": Image_6,
  };

  const [canSubmit, setCanSubmit] = useState(true);

  const { submit, isConfirmed } = useExchangeGenesis(1, id);

  const handleSubmit = useCallback(async () => {
    if (rankingData.points < points) {
      toast({
        title: 'You do not have enough points to redeem this NFT',
        status: 'error',
      });
      return;
    }
    if (!canSubmit) {
      return;
    }
    setCanSubmit(true);
    const response = await getSignature();
    await submit(response.signature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmit, points, rankingData.points]);

  useEffect(() => {
    if (isConfirmed) {
      refetch();
      refetchTotalSupply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  useEffect(() => {
    console.log('rankingData.points', rankingData.points);
  }, [rankingData.points]);

  return (
    <>
      <Text fontSize="xl" style={{ fontWeight: "bold" }}>
        Tier {id}
      </Text>
      <Text fontSize="xl" mb={3} style={{ fontWeight: "bold" }}>
        {name}
      </Text>
      <Image
        src={imageMap[src as keyof typeof imageMap]}
        width={216}
        height={216}
      />
      <Button
        sx={{ border: "1px solid" }}
        colorScheme="white"
        m={4}
        style={{ fontWeight: "normal" }}
        onClick={handleSubmit}
      >
        Redeem
      </Button>

      <Text color="gray" style={{ fontSize: "small" }}>
        {genesisNFTData.remainingSupply[id - 1]} Left
      </Text>
      <Text color="gray" style={{ fontSize: "small" }}>
        {points} Points / 1pc.
      </Text>
    </>
  );
};

export default GenesisTicket;

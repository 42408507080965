import { formatUnits } from 'viem';

// 添加驗證函數
export function isValidNumber(value: string): boolean {
  // 只允許正整數
  const numberRegex = /^[0-9]\d*$/;
  return numberRegex.test(value);
}

export function convertToUSDTUnits(amount: number): number {
  return amount * Math.pow(10, 6);
}

export function convertToUSDTUnitsString(amount: number): number {
  return Number(formatUnits(BigInt(amount), 6));
}
